<template>
        <!-- 找回密码 -->
    <div class="find-account">
        <div class="content">
            <div class="back text-right c99 font-s12" @click="backLogin">
                返回登录<i class="el-icon-d-arrow-right" style="padding-left:0.8%"></i>
            </div>
            <div class="clearfix">
                <div class="content-l fl">
                    <img src="../../assets/image/findAccount.png">
                </div>
                <div class="content-r fr">
                    <div class="title text-center font-s24 c66">
                        重置密码
                    </div>
                    <div class="form-data ">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
                           <el-row class="c06 font-s10">
                               <el-col :span="24">
                                   绑定手机号码
                               </el-col>
                           </el-row>
                            <el-row>
                               <el-col :span="24" >
                                    <el-form-item  prop="telphone">
                                        <el-input  v-model="ruleForm.telphone" placeholder="请输入手机号码"></el-input>
                                    </el-form-item>
                               </el-col>
                           </el-row>
                           <el-row class="c06 font-s10">
                               <el-col :span="24">
                                  验证码
                               </el-col>
                           </el-row>
                            <el-row>
                               <el-col :span="12">
                                     <el-form-item  prop="authCode">
                                        <el-input v-model="ruleForm.authCode" placeholder="请输入验证码"></el-input>
                                    </el-form-item>
                               </el-col>
                               <el-col :span="12">
                                    <el-button type="info" plain  class="auth-code font-s18 c99 el-long" @click="authCodeBtn" :disabled="disabledCode">{{authCodeTxt}}</el-button>
                               </el-col>
                           </el-row>
                           <el-row class="c06 font-s10">
                               <el-col :span="24">
                                  新密码
                               </el-col>
                           </el-row>
                             <el-row>
                               <el-col :span="24">
                                    <el-form-item  prop="pwd">
                                        <el-input v-model="ruleForm.pwd" placeholder="请输入6-12位新密码"></el-input>
                                    </el-form-item>
                               </el-col>
                           </el-row>
                            <el-row class="c06 font-s10">
                               <el-col :span="24">
                                  再次输入密码
                               </el-col>
                           </el-row>
                            <el-row>
                               <el-col :span="24">
                                   <el-form-item  prop="confirmPwd">
                                        <el-input v-model="ruleForm.confirmPwd" placeholder="请再次输入新设置的密码"></el-input>
                                    </el-form-item>
                               </el-col>
                           </el-row>
                             <el-row>
                               <el-col :span="24">
                                   <el-button type="primary" @click="submitForm('ruleForm')" class="el-long">确认</el-button>
                               </el-col>
                           </el-row>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetPhoneCode } from "../../api/api"; //获取验证码
import { RestUserPassword } from "../../api/api"; //重置

export default {
  props: {
    showHide: Boolean //弹框的隐藏显示
  },
  data() {
    var checkTelphone = (rule, value, callback, text) => {
      this.common.Validate.checkTelphone(rule, value, callback, "手机号码");
    };
    var checkPwd = (rule, value, callback, text) => {
      this.common.Validate.checkPwd(rule, value, callback, "密码");
    };
    var checkConfirmPwd = (rule, value, callback, text) => {
      if (!value) {
        return callback(new Error("请输入确认密码"));
      } else {
        setTimeout(() => {
          var strCheck = /^\d{6,12}$|^(?!\d+$)\w{6,12}$/;
          if (strCheck.test(value) == false) {
            callback(new Error("请输正确的格式，6-12位数字加字母！"));
          } else {
            if (this.ruleForm.pwd == value) {
              callback();
            } else {
              callback(new Error("两次密码不一致，请重新输入！"));
            }
          }
        }, 500);
      }
    };
    return {
      showHidePage: false,
      ruleForm: {
        telphone: "", //手机号码
        authCode: "", //验证码
        pwd: "", //密码
        confirmPwd: "" //确认密码
      },
      rules: {
        telphone: [
          { required: true, trigger: "change", validator: checkTelphone }
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "change" }
        ],
        pwd: [{ required: true, trigger: "change", validator: checkPwd }],
        confirmPwd: [
          { required: true, trigger: "change", validator: checkConfirmPwd }
        ]
      },
      authCodeTxt: "获取验证码", //验证码按钮内容
      disabledCode: false //验证码是否可点击
    };
  },
  created() {},
  methods: {
    backLogin() {
      //返回登录
      this.$emit("childFindAccount", this.showHidePage);
    },
    authCodeBtn() {
      //发送验证码
      var checkPhone = /^[0-9]{11}$/;
      if (
        this.ruleForm.telphone == "" ||
        checkPhone.test(this.ruleForm.telphone) == false
      ) {
        this.$message.error("请输入正确的手机号码");
      } else {
        var db = {
          telphone: this.ruleForm.telphone
        };
        this.$axios.post(GetPhoneCode, db).then(
          res => {
            if (res.data.code == 1) {
              this.disabledCode = true;
              var auth_time = 60;
              var auth_timetimer = setInterval(() => {
                auth_time--;
                if (auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                  this.authCodeTxt = "重新发送";
                  this.disabledCode = false;
                } else {
                  this.authCodeTxt = auth_time + "s";
                }
              }, 1000);
            } else {
              this.ruleForm.authCode = "";
            }
          },
          err => {
            this.ruleForm.authCode = "";
          }
        );
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          var db = {
            telphone: this.ruleForm.telphone, //	手机号
            code: this.ruleForm.authCode, //	验证码
            password: this.ruleForm.pwd, //第一次密码
            repassword: this.ruleForm.confirmPwd //	第二次密码
          };
          this.$axios.post(RestUserPassword, db).then(
            res => {
              if (res.data.code == 1) {
                this.backLogin();
              }
              this.$message.error(res.data.msg);
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.find-account {
  background: #f4f4f4;
  height: 100vh;
  position: relative;
  .content {
    position: relative;
    top: 12.5%;
    width: 1000px;
    min-width: 1000px;
    height: 75%;
    min-height: 600px;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    .back {
      padding: 3.8% 2.5% 0 0;
      cursor: pointer;
    }
    .content-l {
      width: 43.3%;
      min-width: 43.3%;
      margin-left: 1.7%;
    }
    .content-r {
      padding-top: 6.6%;
      width: 33.3%;
      min-width: 33.3%;
      margin-right: 5%;
      .title {
        padding-bottom: 3.8%;
        border-bottom: 1px #f4f4f4 solid;
      }
      .form-data {
        padding-top: 5.8%;
        width: 220px;
        margin: 0 auto;
        .el-input {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
